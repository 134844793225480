import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import dayjs from 'dayjs';
import { PackageApiService } from 'features/package/api/package-api.service';
import { PackageFilterBookingForm } from 'features/package/components/package-filter-form/package-booking-filter-form';
import {
  BookingPackageFilterFormValues,
  PackageBookingFilterFormPaginated,
} from 'features/package/components/package-filter-form/type';
import { PackageViewBookingTable } from 'features/package/components/package-table/package-booking';
import { useGetPackageBookingList } from 'features/package/use-cases/get-bookings';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';
const initialFilterValues: BookingPackageFilterFormValues = {
  type_id: undefined,
  status_id: undefined,
  package_name: undefined,
  company_id: undefined,
  customer_name: undefined,
  customer_phone_number: undefined,
  date: [undefined, undefined],
  payment_method: undefined,
};

export const PackageBookingView = () => {
  const [filterValue, setFilterValue] = useState<any>('');
  const { params, updateParams, resetPage } =
    usePaginationParams<PackageBookingFilterFormPaginated>({
      page: 1,
      size: 10,
      ...initialFilterValues,
    });
  const preparePayload = (paramsw: PackageBookingFilterFormPaginated) => {
    const createdAtFrom = paramsw.date?.[0];
    const createdAtTo = paramsw.date?.[1];

    return {
      ...omit(params, ['date']),
      from_date: createdAtFrom
        ? formatDate({
            date: dayjs(createdAtFrom).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      to_date: createdAtTo
        ? formatDate({
            date: dayjs(createdAtTo).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
    };
  };
  const { data, isFetching, refetch } = useGetPackageBookingList(
    preparePayload(params),
    { retry: false },
  );
  const handleFiltersChange = (values: BookingPackageFilterFormValues) => {
    updateParams(values);
    resetPage();
    setFilterValue(values);
  };
  const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
    updateParams(mainParams);
    resetPage();
  };
  const formId = 'users-with-wallet-filters-form';
  const formatDataToCSV = (data1: any) => {
    if (!data1 || !data1.length) {
      return 'data:text/csv;charset=utf-8,';
    }

    // Define the headers
    const headers = [
      'Customer Name',
      'Customer Phone Number',
      'Package Name',
      'Company Name',
      'Type',
      'Facility Name',
      'Amount',
      'Booking Date',
      'Payment Method',
      'Area Name',
      'Booking Status Name',
      'Gender',
      'Order No',
    ];

    // Map the data to the appropriate CSV format
    const csvRows = data1.map((item: any) => [
      item.customer_name,
      item.customer_phone_number,
      item.package_name,
      item.company_name,
      item.package_type,
      item.facility_name,
      item.paid_amount,
      item.booking_date,
      item.payment_method,
      item.area_name,
      item.booking_status_name,
      item.gender,
      item.order_no,
    ]);

    // Combine headers and rows
    const csvContent = [
      headers.join(','), // Add headers as the first row
      ...csvRows.map((row: any) => row.join(',')), // Add data rows
    ].join('\n');

    return `data:text/csv;charset=utf-8,${csvContent}`;
  };
  const generateCSVFile = (dataa: any) => {
    const csvContent = formatDataToCSV(dataa);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
  };
  const start_date = filterValue.date?.[0];
  const end_date = filterValue.date?.[1];
  const handleExport = async () => {
    const apiRes = await PackageApiService().getPackageBookingExport({
      customer_name: filterValue.customer_name,
      customer_phone_number: filterValue.customer_phone_number,
      name: filterValue.name,
      type_id: filterValue.type_id,
      from_date: start_date
        ? formatDate({
            date: dayjs(start_date).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      to_date: end_date
        ? formatDate({
            date: dayjs(end_date).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      size: 10000,
    } as any);
    generateCSVFile(apiRes.items);
  };

  return (
    <>
      <ContentTopBar
        title={'Package bookings'}
        renderHeadingContent={
          <MainFilters onChange={handleMainFiltersChange} />
        }
        renderButtons={
          <>
            <Button onClick={handleExport} type='primary'>
              Export
            </Button>
          </>
        }
      />

      <ContentFilters formId={formId}>
        <PackageFilterBookingForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>
      <PackageViewBookingTable
        isLoading={isFetching}
        data={data}
        refetch={refetch}
        tableParams={params as any}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
