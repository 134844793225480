import { Spin } from 'antd';
import { RcFile } from 'antd/es/upload';
import { ContentTopBar, Loader, Result404 } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { PackageDetailsForm } from 'features/package/components/package-details-form/package-details-form';
import { useDeletePackageCoverImage } from 'features/package/use-cases/delete-cover-image';
import { useDeletePackageGalleryImage } from 'features/package/use-cases/delete-gallery-image';
import { useGetPackage } from 'features/package/use-cases/get-packageId';
import { useUpdatePackage } from 'features/package/use-cases/update-package';
import { useUploadPackageGalleryImage } from 'features/package/use-cases/upload-gallery';
import { useUploadPackageImage } from 'features/package/use-cases/upload-package-image';
import { useState } from 'react';
import { ROUTES } from 'routes/constants';
export const PackageDetails = ({ Id, onSuccess }: any) => {
  const { mutate: mutateUpdateField, isLoading: isLoadingUpdateField } =
    useUpdatePackage();
  const { uploadImage } = useUploadPackageImage();
  const { uploadImage: uploadGalleryImage } = useUploadPackageGalleryImage();
  const {
    mutate: mutateDeletePackageCoverImage,
    isLoading: isLoadingDeleteFieldCoverImage,
  } = useDeletePackageCoverImage();
  const {
    mutate: mutateDeletePackageGalleryImage,
    isLoading: isLoadingDeleteFieldGalleryImage,
  } = useDeletePackageGalleryImage();
  const fieldQueryResult = useGetPackage(Id, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const [isLoadingUploadCoverImage, setIsLoadingUploadCoverImage] =
    useState(false);
  const [isLoadingUploadGalleryImage, setIsLoadingUploadGalleryImage] =
    useState(false);

  const {
    isLoading: isLoadingField,
    isError: isErrorField,
    isFetching: isFetchingField,
    data: packagedata,
  } = fieldQueryResult;

  const getIsLoading =
    isLoadingUpdateField ||
    isFetchingField ||
    isLoadingDeleteFieldCoverImage ||
    isLoadingUploadCoverImage ||
    isLoadingUploadGalleryImage ||
    isLoadingDeleteFieldGalleryImage;

  const handleUpdate = async (
    formValues: any,
    fileList: RcFile[],
    galleryFileList: RcFile[],
  ) => {
    const dayNames: any = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    };
    const sortedPackageDays = formValues.package_days.sort(
      (a: number, b: number) => a - b,
    );
    const orderPayloadDay = sortedPackageDays.map((day: any) => dayNames[day]);
    const updatedFormValues1 = {
      ...formValues,
      package_days: orderPayloadDay,
    };
    const [lat, lang] = (formValues?.latlang || '').split(',');
    const packagestart_time = formValues.package_time_start;
    const packageend_time = formValues.package_time_end;
    const end_time = formValues.packageend_time;
    const start_time = formValues.packagestart_time;
    const timeString1 = end_time.format('HH:mm');
    const timeStart = start_time.format('HH:mm');
    const formatDateOnly = (datetimeString: any) => {
      const date = new Date(datetimeString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    };
    const formatDateTime = (dateString: string, timeString: string) => {
      return `${dateString}T${timeString}:00.000Z`;
    };
    const endOnly = formatDateOnly(packageend_time);
    const startOnly = formatDateOnly(packagestart_time);
    const formattedEndTime = formatDateTime(endOnly, timeString1);
    const formattedstartTime = formatDateTime(startOnly, timeStart);
    const updatedFormValues: any = {
      ...updatedFormValues1,
      // ...formValues
      id: Id,
      lat: lat || '',
      lon: lang.trim() || '',
      month_session_count: +formValues.month_session_count,
      package_type_id: +formValues.package_type_id,
      package_time_start: formattedstartTime,
      package_time_end: formattedEndTime,
      price: +formValues.price,
      total_seat: +formValues.total_seat,
    };
    delete updatedFormValues.packageend_time;
    delete updatedFormValues.packagestart_time;
    delete updatedFormValues.latlang;
    mutateUpdateField(updatedFormValues, {
      onSuccess() {
        if (fileList.length) {
          setIsLoadingUploadCoverImage(true);

          const coverImageformData = new FormData();
          coverImageformData.append('file', fileList[0] as RcFile);

          uploadImage(Id, coverImageformData).finally(() =>
            setIsLoadingUploadCoverImage(false),
          );
        }

        if (galleryFileList.length) {
          setIsLoadingUploadGalleryImage(true);

          galleryFileList.map(async (galleryImage) => {
            const galleryImageformData = new FormData();
            galleryImageformData.append('file', galleryImage as RcFile);

            return uploadGalleryImage(Id, galleryImageformData);
          });

          setIsLoadingUploadGalleryImage(false);
        }

        if (!getIsLoading) onSuccess();
      },
    });
  };

  const handleDeleteImage = () => {
    mutateDeletePackageCoverImage(Id);
  };

  const handleDeleteGalleryImage = (url: string) => {
    mutateDeletePackageGalleryImage({ id: Id, image_url: url });
  };

  return (
    <>
      {isLoadingField ? (
        <Loader />
      ) : isErrorField ? (
        <Result404 />
      ) : (
        <>
          <Spin spinning={getIsLoading}>
            <ContentTopBar
              title={packagedata?.items[0]?.package_name}
              hasBackButton={ROUTES.PACKAGE_MASTER}
            />

            <StyledContentBackground>
              <PackageDetailsForm
                packageData={packagedata?.items[0] as any}
                handleUpdate={handleUpdate}
                handleDeleteImage={handleDeleteImage}
                handleDeleteGalleryImage={handleDeleteGalleryImage}
                disabled={getIsLoading}
              />
            </StyledContentBackground>
          </Spin>
        </>
      )}
    </>
  );
};
