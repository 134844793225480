import { Space, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { PackageFilterFormPaginated } from 'features/package/components/package-filter-form/type';
import { useUpdateStatusPackage } from 'features/package/use-cases/update-status';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Package } from 'types/package';
import { UserWithithdraw } from 'types/withdraw';
// const { useBreakpoint } = Grid;

// ../package-filter-form/type
// ../users-with-wallet-filter-form/type
// const { useBreakpoint } = Grid;

type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  tableParams: PackageFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<PackageFilterFormPaginated>>;
};

export const PackageViewTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  // const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { mutate: mutateUpdatePackage, isLoading: isLoadingUpdatePackage } =
    useUpdateStatusPackage();
  if (!permissions) return null;
  // const screens = useBreakpoint();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserWithithdraw> | SorterResult<UserWithithdraw>[],
  ) => {
    const sortBy = () => {
      if (Array.isArray(sorter)) return;

      const sortOrder =
        sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`;

      if (sorter?.field === 'last_transaction_date') {
        return {
          order_by_balance: undefined,
          order_by_transaction: sortOrder,
        };
      }
    };

    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      ...sortBy(),
    });
  };
  // const handleValue = (matchId: any) => {
  //   navigate(getRoute(ROUTES.WITHDRAW_detail, matchId));
  // };
  // const handleValueReject = (matchId: any) => {
  //   navigate(getRoute(ROUTES.WITHDRAW_REJECT, matchId));
  // };
  // const items = (id: any): MenuProps['items'] => {
  //   return [
  //     {
  //       label: 'Approved',
  //       onClick: () => handleValue(id),
  //       key: '0',
  //     },
  //     {
  //       label: 'Reject',
  //       onClick: () => handleValueReject(id),
  //       key: '1',
  //     },
  //   ];
  // };
  // const tableWidth = screens.xxl ? { x: 1440 } : { x: 1100 };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('package.packagefound')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Package>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        onRow={(record: any) => {
          return {
            className: record.status_id !== 1 ? 'clickable-row' : '',
            onClick: () => {
              navigate(getRoute(ROUTES.PACKAGE_EDIT, record.id));
            },
          };
        }}
      >
        <Column
          title={t('package.table.packageName')}
          dataIndex={'package_name'}
          // width={screens.xxl ? 'auto' : 170}
          render={(_, record: any) => record.package_name}
        />
        <Column<UserWithithdraw>
          title={t('package.table.area')}
          dataIndex={'area_name'}
          // width={screens.xxl ? 'auto' : 170}
        />
        <Column<UserWithithdraw>
          title={t('package.table.facility')}
          dataIndex={'facility_name'}
          // width={screens.xxl ? 'auto' : 130}
        />
        <Column<UserWithithdraw>
          title={t('package.table.companyName')}
          dataIndex={'company_name'}
          // sorter={true}
          // sortDirections={['descend', 'ascend', 'descend']} // workaround to remove null as an option
          render={(_, record: any) => <span>{record.company_name}</span>}
        />
        <Column<UserWithithdraw>
          title={'Total Seat'}
          dataIndex={'total_seat'}
          render={(_, record: any) => <span>{record.total_seat}</span>}
        />
        <Column<UserWithithdraw>
          title={'Available Seat'}
          dataIndex={'available_seat'}
          render={(_, record: any) => <span>{record.available_seat}</span>}
        />
        <Column<UserWithithdraw>
          title={t('package.table.action')}
          dataIndex={'last_transaction_date'}
          render={(_, record: any) => (
            <Space>
              <Switch
                // disabled={!permissions?.facilities.edit}
                defaultChecked={record.is_active}
                checked={record.is_active}
                loading={isLoadingUpdatePackage}
                onChange={(isChecked) => {
                  mutateUpdatePackage({
                    package_id: record.id,
                    is_active: isChecked == false ? false : true,
                  });
                  // Update the is_active status locally
                  record.is_active = isChecked;
                }}
                onClick={(checked, event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
