import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import { PackageFilterForm } from 'features/package/components/package-filter-form/package-filter-form';
import {
  PackageFilterFormPaginated,
  PackageFilterFormValues,
} from 'features/package/components/package-filter-form/type';
import { PackageViewTable } from 'features/package/components/package-table/package-view';
import { useGetPackageList } from 'features/package/use-cases/get-package';
import { usePaginationParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
const initialFilterValues: PackageFilterFormValues = {
  type_id: undefined,
  name: undefined,
  company_id: undefined,
};
const { useBreakpoint } = Grid;

export const PackageView = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { params, updateParams, resetPage } =
    usePaginationParams<PackageFilterFormPaginated>({
      page: 1,
      size: 10,
      ...initialFilterValues,
    });
  const { data, isFetching } = useGetPackageList(params, { retry: false });
  const handleFiltersChange = (values: PackageFilterFormValues) => {
    updateParams(values);
    resetPage();
  };
  const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
    updateParams(mainParams);
    resetPage();
  };
  const formId = 'users-with-wallet-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('package.heading')}
        renderHeadingContent={
          <MainFilters
            // filterTypes={['companyType', 'company']}
            onChange={handleMainFiltersChange}
          />
        }
        // renderHeadingContent={
        //   <CompanySelect
        //     allowClear={false}
        //     size='large'
        //   />
        // }
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              href={ROUTES.PACKAGE_CREATE}
            >
              {t('package.buttons.addpackage')}
            </Button>
          </>
        }
      />

      <ContentFilters formId={formId}>
        <PackageFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>
      <PackageViewTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
      />

      <Outlet />
    </>
  );
};
