import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import {
  Booking,
  BookingComment,
  BookingListItem,
  BookingType,
  CompanyType,
  PaymentMethod,
  PaymentStatus,
  RecurringBooking,
  RecurringBookingListItem,
  RecurringBookingSingleBookingsListItem,
} from 'types';
import { Weekdays } from 'types/days';

export type CreateBookingDTO = {
  first_name: string;
  last_name: string;
  phone_number: string;
  start_date: string;
  end_date: string;
  sport_id: string;
  field_id: string;
  payment_method: PaymentMethod;
  total_cost: number;
  discount: number;
};
export type AllBookingStatsParams = {
  from_date?: any; // ISO8601_FORMAT
  to_date?: any; // ISO8601_FORMAT
  company_type?: any;
  company_id?: any;
  facility_id?: any;
};

export type UpdateBookingDTO = {
  id: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  discount?: number;
  comment?: string;
  is_cancelled?: boolean;
  payment_status?: PaymentStatus;
  client_showed_up?: boolean;
};

export type UpdateRecurringBookingDTO = {
  id: string;
  discount: number;
};

export interface GetBookingsParams extends PaginatedRequestParams {
  facility_id?: string;
  from_date?: string; // ISO8601_FORMAT
  to_date?: string; // ISO8601_FORMAT
  company_type?: CompanyType;
  full_user_name?: string;
  phone_number?: string;
  company_id?: string;
  field_id?: string;
  is_cancelled?: boolean;
}

export interface GetBookingCommentParams extends PaginatedRequestParams {
  id: string;
}

export interface GetRecurringBookingSingleBookingsParams
  extends PaginatedRequestParams {
  fixed_booking_id?: string;
}

export interface GetRecurringBookingsParams extends PaginatedRequestParams {
  fixed_booking_type?: BookingType;
  company_id?: string;
  company_type?: CompanyType;
  is_cancelled?: boolean;
  phone_number?: string;
  field_id?: string;
  facility_id?: string;
  full_user_name?: string;
  date_from?: string; // ISO8601_FORMAT
  date_to?: string; // ISO8601_FORMAT
}

export type BookingsResponse = PaginatedResponse<BookingListItem>;
export type RecurringBookingSingleBookingsResponse =
  PaginatedResponse<RecurringBookingSingleBookingsListItem>;

export type RecurringBookingsResponse =
  PaginatedResponse<RecurringBookingListItem>;
export type BookingCommentResponse = PaginatedResponse<BookingComment>;

export type AddBookingCommentDTO = {
  id: string;
  text: string;
};

export type BookingCommentParams = {
  id: string;
} & PaginatedRequestParams;

export type RecurringBookingPredictionDTO = {
  field_id?: string;
  date_from?: string;
  date_to?: string;
  time_from?: string;
  time_to?: string;
  weekday?: Weekdays;
};

export type RecurringBookingPredictionResponse = {
  detail: {
    start_date: string;
    end_date: string;
    reason: string;
  }[];
  items: {
    start_date: string;
    end_date: string;
    total_cost: number;
  }[];
};

export type PaginatedRecurringBookingPredictionResponse =
  RecurringBookingPredictionResponse &
    PaginatedResponse<RecurringBookingPredictionResponse>;

export type CreateRecurringBookingDTO = {
  date_from?: string;
  date_to?: string;
  weekday?: Weekdays;
  start_time?: string;
  end_time?: string;
  field_id?: string;
  type?: BookingType;
  sport_id?: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  payment_method?: PaymentMethod;
  total_cost?: number;
  discount_per_booking?: number;
  text?: string;
};

export const BookingApiService = () => {
  const createBooking = async (payload: CreateBookingDTO) => {
    const response = await apiClient({
      endpoint: '/admin/bookings',
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getBookings = async (
    params: GetBookingsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<BookingsResponse>({
      ...callConfig,
      endpoint: `/admin/bookings`,
      params,
    });

    return response.data;
  };

  const getRecurringBookings = async (
    params: GetRecurringBookingsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<RecurringBookingsResponse>({
      ...callConfig,
      endpoint: `/admin/fixed-bookings`,
      params,
    });

    return response.data;
  };

  const getBooking = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Booking>({
      ...callConfig,
      endpoint: `/admin/bookings/${id}`,
      // /admin/bookings/
    });

    return response.data;
  };

  const getRecurringBooking = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<RecurringBooking>({
      ...callConfig,
      endpoint: `/admin/fixed-bookings/${id}`,
    });

    return response.data;
  };

  const getRecurringBookingSingleBookings = async (
    params: GetRecurringBookingSingleBookingsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { fixed_booking_id, ...restParams } = params;
    const response = await apiClient<RecurringBookingSingleBookingsResponse>({
      ...callConfig,
      endpoint: `/admin/fixed-bookings/${fixed_booking_id}/bookings`,
      params: restParams,
    });

    return response.data;
  };

  const updateBooking = async (
    payload: UpdateBookingDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Booking>({
      ...callConfig,
      endpoint: `/admin/bookings/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const updateRecurringBooking = async (
    payload: UpdateRecurringBookingDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<string>({
      ...callConfig,
      endpoint: `/admin/fixed-bookings/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const cancelBooking = async (id: string) => {
    const response = await apiClient({
      endpoint: `/admin/bookings/${id}/cancel`,
      method: 'POST',
    });

    return response.data;
  };

  const recurringBookingPrediction = async (
    payload: RecurringBookingPredictionDTO,
  ) => {
    const response =
      await apiClient<PaginatedRecurringBookingPredictionResponse>({
        endpoint: '/admin/fixed-bookings/predict',
        method: 'POST',
        data: payload,
        params: { size: Number.MAX_SAFE_INTEGER },
      });

    return response.data;
  };

  const cancelRecurringBooking = async (id: string) => {
    const response = await apiClient({
      endpoint: `/admin/fixed-bookings/${id}/cancel`,
      method: 'POST',
    });

    return response.data;
  };

  const getBookingComments = async (
    params: BookingCommentParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id } = params;

    const response = await apiClient<BookingCommentResponse>({
      ...callConfig,
      endpoint: `/admin/bookings/${id}/comments`,
      params,
    });

    return response.data;
  };

  const createRecurringBooking = async (payload: CreateRecurringBookingDTO) => {
    const response = await apiClient({
      endpoint: '/admin/fixed-bookings',
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const addBookingComment = async (payload: AddBookingCommentDTO) => {
    const { id, ...data } = payload;

    const response = await apiClient({
      endpoint: `/admin/bookings/${id}/comments`,
      method: 'POST',
      data,
    });

    return response.data;
  };

  const addFixedBookingComment = async (payload: AddBookingCommentDTO) => {
    const { id, ...data } = payload;

    const response = await apiClient({
      endpoint: `/admin/fixed-bookings/${id}/comments`,
      method: 'POST',
      data,
    });

    return response.data;
  };

  const getFixedBookingComments = async (
    params: BookingCommentParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id } = params;

    const response = await apiClient<BookingCommentResponse>({
      ...callConfig,
      endpoint: `/admin/fixed-bookings/${id}/comments`,
      params,
    });

    return response.data;
  };

  return {
    cancelBooking,
    createBooking,
    getBookings,
    getRecurringBooking,
    getRecurringBookings,
    getRecurringBookingSingleBookings,
    cancelRecurringBooking,
    getBooking,
    updateBooking,
    recurringBookingPrediction,
    createRecurringBooking,
    updateRecurringBooking,
    getBookingComments,
    addBookingComment,
    addFixedBookingComment,
    getFixedBookingComments,
  };
};
