import { DeleteFilled } from '@ant-design/icons';
import {
  Button,
  Grid,
  Image,
  message,
  Popconfirm,
  Space,
  TablePaginationConfig,
  Tooltip,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { CommunityApiService } from 'features/community/api/community-api.service';
import { PackageFilterFormPaginated } from 'features/package/components/package-filter-form/type';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Package } from 'types/package';
import { UserWithithdraw } from 'types/withdraw';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
const { useBreakpoint } = Grid;

type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: any;
  refetch?: any;
  tableParams: PackageFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<PackageFilterFormPaginated>>;
};

export const ReportPostViewTable = ({
  data,
  refetch,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();
  // const { useBreakpoint } = Grid;
  // const { mutate: mutateUpdatePackage, isLoading: isLoadingUpdatePackage } =
  //   useUpdateStatusPackage();
  if (!permissions) return null;
  // const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  // const allowedAudioExtensions = ['mp3', 'wav', 'ogg', 'mov'];
  // const isImageExtension = (url: any) => {
  //   const extension = url.split('.').pop().toLowerCase();
  //   return allowedImageExtensions.includes(extension);
  // };
  // const isAudioExtension = (url: any) => {
  //   const extension = url.split('.').pop().toLowerCase();
  //   return allowedAudioExtensions.includes(extension);
  // }
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserWithithdraw> | SorterResult<UserWithithdraw>[],
  ) => {
    const sortBy = () => {
      if (Array.isArray(sorter)) return;

      const sortOrder =
        sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`;

      if (sorter?.field === 'last_transaction_date') {
        return {
          order_by_balance: undefined,
          order_by_transaction: sortOrder,
        };
      }
    };

    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      ...sortBy(),
    });
  };
  const handleDelete = async (record: any) => {
    try {
      const datapayload = {
        post_id: record.post_id,
      };
      await CommunityApiService().reportDelete(datapayload);
      refetch();
    } catch (error: any) {
      message.error(error);
    }
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge text={'Report User'} count={data?.total || 0} />
      </StyledTableHeader>
      <Table<Package>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        className='rept_table'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
      >
        <Column
          title={'Title'}
          dataIndex={'post_title'}
          render={(_, record: any) => (
            <Space>
              <Tooltip title={record.post_title}>{record.post_title}</Tooltip>
            </Space>
          )}
        />

        <Column<UserWithithdraw>
          title={'Media'}
          dataIndex={'area_name'}
          // width={screens.xxl ? 'auto' : 170}
          render={(_, record: any) => (
            <div className='mediagrp'>
              {record?.media_list
                ?.slice(0, 4)
                .map((res: any, index: number) => (
                  <Image
                    key={index}
                    src={res.media_url}
                    style={{
                      width: '100px',
                      height: '80px',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      marginRight: '8px',
                    }}
                  />
                ))}
            </div>
            // <div className='mediagrp'>
            //   {record?.media_list
            //     ?.slice(0, 4)
            //     .map((res: any, index: number) => {
            //       if (isImageExtension(res.media_url)) {
            //         return (
            //           <Image
            //             key={index}
            //             src={res.media_url}
            //             style={{
            //               width: '100px',
            //               height: '80px',
            //               objectFit: 'cover',
            //               borderRadius: '10px',
            //               marginRight: '8px', // Optional: Add spacing between images
            //             }}
            //           />
            //         );
            //       } else if (isAudioExtension(res.media_url)) {
            //         console.log(`Loading audio: ${res.media_url}`);
            //         return (
            //           // <video key={index} controls style={{ display: 'block', marginTop: '10px' }}>
            //           //   <source src={res.media_url}  />
            //           //   Your browser does not support the audio element.
            //           // </video>

            //           <video controls autoPlay loop muted>
            //             <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4"></source>
            //           </video>
            //         );
            //       }
            //       return null; // For unsupported media types
            //     })}
            // </div>
          )}
        />
        <Column
          title={'Group'}
          dataIndex={'group_name'}
          render={(_, record: any) => <Space>{record.group_name}</Space>}
        />
        <Column<UserWithithdraw>
          title={'Post By'}
          dataIndex={'post_creator_name'}
          render={(_, record: any) => (
            <Space>
              {record.post_creator_name}
              {record.post_creator_phone_number}
            </Space>
          )}
          width={screens.xxl ? 'auto' : 170}
        />

        <Column<UserWithithdraw>
          title={'Post Date'}
          dataIndex={'total_report'}
          width={screens.xxl ? 'auto' : 270}
          render={(_, record: any) => (
            <>
              {formatDate({
                date: record.post_create_on,
                format: 'date',
                timeZone: DEFAULT_TIMEZONE,
              } as any)}
            </>
          )}
        />
        <Column<UserWithithdraw>
          title={'Total Report'}
          dataIndex={'total_report'}
          // width={screens.xxl ? 'auto' : 170}
          render={(_, record: any) => (
            <div className='btn_rpt'>
              <Space
                onClick={() =>
                  navigate(getRoute(ROUTES.REPORT_POST_DETAILS, record.post_id))
                }
                style={{ cursor: 'pointer' }}
              >
                {`${record.total_report}`}
              </Space>
            </div>
          )}
        />
        <Column<UserWithithdraw>
          title={t('package.table.action')}
          dataIndex={'last_transaction_date'}
          render={(_, record: any) => (
            <Space>
              <Popconfirm
                title='Delete the Post'
                description='Are you sure to delete this Post?'
                onConfirm={() => handleDelete(record)}
                okText='Yes'
                cancelText='No'
              >
                <Button>
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
