import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { fieldsQueryKeys } from 'features/fields';
import { PackageApiService } from 'features/package/api/package-api.service';
import { queryClient } from 'providers';
// ../api/social-api.service
const { updateStatusPackage } = PackageApiService();

export const useUpdateStatusPackage = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return updateStatusPackage(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: fieldsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: fieldsQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success('Update successfully');
      },
    },
  );
};
