import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { axiosInstance } from 'api/axios-instance';
import { CONFIG } from 'config';
// import { constant } from 'lodash';
import {
  BookingType,
  CalendarSlot,
  Company,
  CompanyType,
  Duration,
  Facility,
  Field,
  Source,
  Sport,
  SportType,
  Time,
  Transaction,
  User,
} from 'types';

export type FieldBooking = {
  id: string;
  client: Pick<User, 'first_name' | 'last_name'>;
  start_date: string;
  end_date: string;
  source: Source;
  transaction: Transaction[];
  type: BookingType;
};
export type TimeRange = {
  start_time: string;
  end_time: string;
  slot_price: number;
};
export type exception_ranges = {
  start_date: string;
  end_date: string;
  time_ranges: TimeRange;
};

type FieldTimeRange = {
  start_time: string;
  end_time: string;
  slot_price: number;
};

// export type FieldSlot = {
//   Date:string;
//   Slots:CalendarSlot[];
// };

export type FieldWithBookings = {
  id: string;
  name: string;
  name_arabic: string;
  type: SportType;
  price: number;
  max_players: number;
  sports: Pick<Sport, 'id' | 'name' | 'icon'>[];
  time_slot: Duration;
  width: number;
  length: number;
  facility: Pick<Facility, 'id' | 'name' | 'name_arabic'> & {
    company: Pick<Company, 'id' | 'name' | 'name_arabic'>;
  };
  bookings: FieldBooking[];
  open_from: Time;
  open_to: Time;
  first_range_start_time: Time;
  first_range_end_time: Time;
  first_range_slot_price: number;
  second_range_start_time: Time;
  second_range_end_time: Time;
  second_range_slot_price: number;
  cover_image_url?: string;
  time_ranges?: FieldTimeRange[];
  exception_ranges?: exception_ranges[];
  field_Slots: FieldWithSlotsBookingsNPrices[];
};

export type FieldsBookingsResponse = PaginatedResponse<FieldWithBookings>;

export type FieldsBookingsParams = {
  date: string;
  facility_id?: string;
  company_id?: string;
  players?: number;
  company_type?: CompanyType;
  sport_id?: string;
} & PaginatedRequestParams;

export type FieldBookingsParams = {
  id?: string;
  date: string;
  field_id?: string;
  sport_id?: string;
};

export type FieldBookingsResponse = FieldWithBookings;
export type FieldsResponse = PaginatedResponse<Field>;

export type FieldsParams = {
  is_active?: boolean;
  field_name?: string;
  facility_id?: string;
} & PaginatedRequestParams;

export type CreateFieldDTO = {
  name?: string;
  name_arabic?: string;
  type?: SportType;
  price?: number;
  max_players?: number;
  time_slot?: string;
  sport_ids?: string[];
  width?: number;
  length?: number;
  amenity_ids?: string[];
  chargeable_amenity_ids?: string[];
  categories?: string[];
  facility_id?: string;
};

export type UpdateFieldDTO = {
  is_active?: boolean;
  id: string;
  rank?: number;
} & CreateFieldDTO;

export type DeleteFieldImageDTO = {
  id: string;
  image_url: string;
};

///////////////////////////////////////
/*************************************/
export type FieldSlotsParams = {
  FieldId: string;
  StartDate: string;
  EndDate: string;
};
export type FieldWithSlotsBookingsNPrices = {
  Date: string;
  Slots: CalendarSlot[];
};

export type FieldSlotsBookingsNPricesResponse = FieldWithSlotsBookingsNPrices;

/*************************************/
///////////////////////////////////////

export const FieldsApiService = () => {
  const getFieldsBookings = async (
    params: FieldsBookingsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<FieldsBookingsResponse>({
      ...callConfig,
      endpoint: `/admin/fields/bookings`,
      params,
    });

    return response.data;
  };

  const getFieldSlotsPricesNBookings = async (
    payload: FieldSlotsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    // const { FieldId, ...restParams } = params;

    const response = await apiClient<FieldWithSlotsBookingsNPrices[]>({
      ...callConfig,
      endpoint: `/calendar`, //?FieldId=${FieldId}&StartDate=${restParams.StartDate}&EndDate=${restParams.EndDate}`,
      data: payload,
      method: 'POST',
    });

    return response.data;
  };

  const getFieldBookings = async (
    params: FieldBookingsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...restParams } = params;
    const response = await apiClient<FieldBookingsResponse>({
      ...callConfig,
      endpoint: `/admin/fields/${id}/bookings`,
      params: restParams,
    });
    response.data.bookings = [];
    response.data.time_ranges = [];
    response.data.exception_ranges = [];
    /*************************/
    /**Start: Get the actual slots after calculating the All Days time ranges,
     **       Exceptional Days time ranges and Prices based on time ranges directly
     **       from the API..**/
    /* Call Slot Generation API to get Day wise slots and their prices from database..*/

    // const _choosendate = new Date(`${params.date}T00:00:00`);
    // // _choosendate.setDate(_choosendate.getDate() - 1) ;
    // const _startDate = _choosendate.toISOString().split('T')[0];
    // _choosendate.setDate(_choosendate.getDate() + 6);
    // const _endDate = _choosendate.toISOString().split('T')[0];
    // //  _startDate.addDays(5)

    const _choosendate = new Date(`${params.date}T00:00:00`);
    const _startDate = params.date;
    //const _startDate = params.date;
    _choosendate.setDate(_choosendate.getDate() + 7);
    const _endDate = _choosendate.toISOString().split('T')[0];

    const fieldSlotParam: FieldSlotsParams = {
      FieldId: params.id ?? '',
      StartDate: _startDate,
      EndDate: _endDate,
    };

    // Merge booking and slot data
    await getFieldSlotsPricesNBookings(fieldSlotParam).then(
      (fieldSlotsData) => {
        if (fieldSlotsData !== undefined && fieldSlotsData !== null) {
          response.data.field_Slots = fieldSlotsData;

          return response.data;
        }
      },
    );

    return response.data;
  };

  const getFields = async (
    params: FieldsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<FieldsResponse>({
      ...callConfig,
      endpoint: `/admin/fields`,
      params,
    });

    return response.data;
  };

  const updateField = async (
    payload: UpdateFieldDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Field>({
      ...callConfig,
      endpoint: `/admin/fields/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  const createField = async (payload: CreateFieldDTO) => {
    const response = await apiClient({
      endpoint: `/admin/fields`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const uploadFieldCoverImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.LI3IB_API_BASE_URL,
      '/identity/',
      '/sports/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/fields/${id}/cover_image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const getField = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<Field>({
      ...callConfig,
      endpoint: `/admin/fields/${id}`,
    });

    return response.data;
  };

  const deleteFieldCoverImage = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/fields/${id}/cover_image`,
      method: 'DELETE',
    });

    return response.data;
  };

  const uploadFieldGalleryImage = async (id: string, formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.LI3IB_API_BASE_URL,
      '/identity/',
      '/sports/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/admin/fields/${id}/images`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };

  const deleteFieldGalleryImage = async (
    payload: DeleteFieldImageDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, image_url } = payload;

    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/fields/${id}/images/remove`,
      method: 'POST',
      data: { image_url },
    });

    return response.data;
  };

  const deleteTimeRange = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/time_ranges/${id}`,
      method: 'DELETE',
    });

    return response.data;
  };

  const replaceSubstring = (
    input: string,
    target: string,
    replacement: string,
  ): string => {
    const startIndex = input.indexOf(target);
    const endIndex = startIndex + target.length;
    const replacedStr =
      input.slice(0, startIndex) + replacement + input.slice(endIndex);

    return replacedStr;
  };

  return {
    createField,
    getFieldsBookings,
    getFieldBookings,
    getField,
    getFields,
    updateField,
    uploadFieldCoverImage,
    deleteFieldCoverImage,
    uploadFieldGalleryImage,
    deleteFieldGalleryImage,
    deleteTimeRange,
  };
};
